<template>
  <v-col class="modcam mt-1">
    <v-col class="shades white pa-6">
      <v-row  class="px-0">
        <v-col class="pa-2" cols="12" >
          <div class="body-1 font-weight-bold my-2" style="color: #484848;">사진 등록<span class="caption rouge--text"> *필수</span></div>
          <v-radio-group v-model="form.thumbnail">
            <div class="hidden-sm-and-down" style="width: 666px"></div>
            <v-data-table :headers="[{ text: 'No', width: '32px', sortable: false},
                            { text: $vuetify.breakpoint.smAndDown ? '사진' : '미리보기', width: '104px', sortable: false},
                            { text: $vuetify.breakpoint.smAndDown ? '대표' : '대표사진', width: '72px', sortable: false},
                            { text: $vuetify.breakpoint.smAndDown ? '순서' : '순서 변경', width: '104px', sortable: false},
                            { text: '등록/삭제', width: '112px', sortable: false}]"
                          :items="form.thumbnails" item-key="name" hide-default-footer mobile-breakpoint="0"
                          :items-per-page="-1"
                          style="backgroud-color: transparent; border-top: 1px solid rgba(0,0,0,0.12); border-bottom: 1px solid rgba(0,0,0,0.12);">
              <template v-slot:header="props">
                <tr class="text-center pa-0">
                  <th v-for="(header, idx) in props.headers" :key="header.text" :class="idx > 2 ? 'pa-0 pr-2 text-right' : 'pa-0'"
                      :style="'width:' + header.width">
                    <span class="body-1 shades--text text--black">{{ header.text }}</span>
                  </th>
                </tr>
              </template>
              <template v-slot:item="props">
                <tr>
                  <td class="ml-1 pa-0 text-center">{{ props.index + 1}}</td>
                  <td class="px-0 py-2 text-center">
                    <img :src="props.item" class="hidden-sm-and-down" @error="(e) => orgImage(e, props.item)" style="width: 96px; height: 96px"/>
                    <img :src="props.item" class="hidden-md-and-up" @error="(e) => orgImage(e, props.item)" style="width: 48px; height: 48px"/>
                  </td>
                  <td class="text-center pl-6 pr-0">
                    <v-radio :value="props.item" hide-details></v-radio>
                  </td>
                  <td class="pa-0">
                    <v-btn color="rouge" class="ma-0" outlined @click="swapImage(props.index - 1, props.index)" style="min-width: 0; width: 40px; padding: 0">
                      <v-icon>keyboard_arrow_up</v-icon>
                    </v-btn>
                    <v-btn color="rouge" class="ma-0 ml-2" outlined @click="swapImage(props.index, props.index + 1)" style="min-width: 0; width: 40px; padding: 0">
                      <v-icon class="mr">keyboard_arrow_down</v-icon>
                    </v-btn>
                  </td>
                  <td class="pa-0" justify="center">
                    <v-btn color="rouge" class="ma-0" outlined @click="delImage(props.index)"
                          style="max-width: 80px">
                    <!--  :style="$vuetify.breakpoint.smAndDown ? 'min-width: 40px; width: 40px' : 'max-width: 80px'"> -->
                      <v-icon>close</v-icon>
                      <span class="ml-2">삭제</span>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-radio-group>
          <v-row class="justify-end py-4 pr-2">
            <!--<v-btn class="rouge white-two&#45;&#45;text ma-0 ml-4" flat-->
                   <!--:style="$vuetify.breakpoint.smAndDown ? 'min-width: 40px; width: 40px' : 'max-width: 80px'">-->
              <!--<v-icon class="mr-2">fas fa-plus</v-icon>-->
              <div id="hidden-uploaded-files" style="display:none"></div>
              <vue-dropzone ref="myVueDropzone" id="dropzone"
                            :options="dropzoneOptions"
                            @vdropzone-files-added="addedFile"
                            @vdropzone-complete="completeFile"
                            @vdropzone-success="successFile"
                            @vdropzone-thumbnail="thumbnailFile"
                            class="rouge white-two--text ma-0">
              </vue-dropzone>
            <!--</v-btn>-->
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-col>
</template>

<script>
  import firebase from "firebase";
  import AWS from "aws-sdk";

  import campaignMixin from '@/components/shared/campaignMixin'

  export default {
    name: "Page5",
    mixins: [campaignMixin],
    props: ['camData', 'option'],
    components: {
      vueDropzone: () => import('@/components/shared/dropzone/vue-dropzone')
    },
    data: function () {
      return {
        form: {
          thumbnails: this.camData.thumbnails || [],
          thumbnail: this.camData.thumbnail || ''
        },
        thumbnails: []
      }
    },
    computed: {
      dropzoneOptions: function () {
        return {
          url: process.env.VUE_APP_API_URL + '/utils/upload',
          // thumbnailWidth: 150,
          // maxFilesize: 0.5,
          // headers: { 'My-Awesome-Header': 'header value' },
          paramName: 'file',
          acceptedFiles: 'image/*',
          previewsContainer: '#hidden-uploaded-files',
          dictDefaultMessage: '<i class="fas fa-plus"></i><span class="hidden-sm-and-down">&nbsp;&nbsp;&nbsp;추가</span>',
          resizeWidth: 1980,
          resizeHeight: 1980,
          resizeMethod: 'contain',
          createImageThumbnails: true,
          thumbnailWidth: 800,
          thumbnailHeight: 800,
          thumbnailMethod: 'contain'
        }
      }
    },
    watch: {
      camData(value) {
        this.form = {
          thumbnails: value.thumbnails || [],
          thumbnail: value.thumbnail || ''
        };
      },
      'form.thumbnail': function() {
        this.$store.commit('setDirty', true);
        this.$emit('updateEvent', this.form);
      }
    },
    methods: {
      swapImage (i, j) {
        if (Math.min(i, j) >= 0 && Math.max(i, j) < this.form.thumbnails.length) {
          let rows = [this.form.thumbnails[i], this.form.thumbnails[j]];
          this.form.thumbnails.splice(i, 2, rows[1], rows[0] );
          // this.$emit('updateEvent', this._.defaults(this.form, this.camData));
          this.$emit('updateEvent', this.form);
          this.$store.commit('setDirty', true);
        }
      },
      delImage (index) {
        if (this.form.thumbnails.length > 1) {
          if (this.form.thumbnails[index] === this.form.thumbnail) {
            this.form.thumbnail = (this.form.thumbnails.length > 1 ? this.form.thumbnails[0] : '');
          }
          this.form.thumbnails.splice(index, 1);
          this.$emit('updateEvent', this.form);
          this.$store.commit('setDirty', true);
        }
      },
      addedFile () {
        this.isFade = false
      },
      completeFile () {
        this.isFade = true
      },
      successFile () {
      },
      async thumbnailFile (file, dataUrl) {
        if (this.camData.id === undefined) {
          this.$store.commit('setMsg', '캠페인 오류: 새로고침을 해주세요.');
          this.$store.commit('setShowSnackbar', true);
        }
        else if (file.width > 16383 || file.height > 16383) {
          this.$store.commit('setMsg', '가로/세로 사이즈 16383이하만 가능합니다.');
          this.$store.commit('setShowSnackbar', true);
        }
        else {
          this.$store.commit('setLoading', true);
          let extension = file.name.split('.');
          let filename = file.upload.uuid.replace(/[^A-Fa-f0-9]/g, '') + '.' + extension[extension.length - 1];

          let token = await firebase.auth().currentUser.getIdToken(true);
          AWS.config.credentials = new AWS.WebIdentityCredentials({
            RoleArn: 'arn:aws:iam::557258438147:role/FirebaseS3Role',
            WebIdentityToken: token // Access token from identity provider
          });
          AWS.config.region = 'ap-northeast-2';

          let buf = null;
          if(file.size > 5000000) {
            buf = new Buffer(dataUrl.replace(/^data:image\/\w+;base64,/, ""), 'base64');
          } else {
            buf = new Buffer(file.dataURL.replace(/^data:image\/\w+;base64,/, ""), 'base64');
          }

          let upload = new AWS.S3.ManagedUpload({
            params: {
              Bucket: 'supermembers.upload',
              Key: 'org/cam/' + this.camData.id + '/' + 'thumb_' + filename,
              // ACL : 'public-read',
              Body: buf,
              ContentEncoding: 'base64',
              ContentType: file.type
            }
          });

          let res = await upload.promise();
          if ((res || {}).Location) {
            let url = 'https://d21ebri204ww32.cloudfront.net/resize/cam/' + this.camData.id + '/thumb_' + filename;
            url = url.replace(/\.[^/.]+$/, '.webp');
            url = url + '?f=jpeg';
            this.form.thumbnails.push(url);
            this.thumbnails.push({
              url: url,
              Photo: file.dataURL,
              Thumbnail: dataUrl,
              org: file.name,
              name: filename,
              thumbname: 'thumb_' + filename,
              type: file.type
            });
            if (!this.form.thumbnail) {
              this.form.thumbnail = url;
            }
            // this.$emit('updateEvent', this._.defaults(this.form, this.camData));
            this.$emit('updateEvent', this.form);
            this.$store.commit('setDirty', true);
          }
          else {
            this.$store.commit('setMsg', '이미지 업로드에 실패하였습니다.');
            this.$store.commit('setShowSnackbar', true);
          }
          this.$store.commit('setLoading', false);
        }
      },
      orgImage(e, url) {
        let img = this._.find(this.thumbnails, {url: url});
        if (img) {
          e.target.src = img.Thumbnail;
        }
      }
    }
  }
</script>

<style scoped>
  ::v-deep .v-list-item {
    padding: 0;
    font-size: 14px;
  }

  @media only screen and (max-width: 959px) {
    ::v-deep .vue-dropzone.dropzone {
      min-width: 40px;
      min-height: 36px;
      padding: 7px 0px;
    }
  }

  @media only screen and (min-width: 960px) {
    ::v-deep .vue-dropzone.dropzone {
      min-width: 88px;
      min-height: 36px;
      padding: 7px 0px;
    }
  }

  ::v-deep .dz-default.dz-message {
    margin: 0
  }
</style>